<template>
    <div>
        <b-spinner variant="primary" v-if="isLoading" large class="pageLoadSpinners" style="position: fixed;"/>
        <b-card class="output_field">
            <b-row>
                <b-col>
                    <b-form-group label="Application No.">
                        <b>{{applicationData.applicationNumber}}</b>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Student Name">
                        <div class="add_edu_link">
                            <a @click.prevent="changeRoute(studentData)">{{studentData.name}}</a>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Intake">
                        <b>{{applicationData.dispIntake}}</b>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="No. of Suggested Course">
                        <b v-if="applicationData.selectedCollege !== undefined">{{applicationData.selectedCollege.length ? applicationData.selectedCollege.length : 0}}</b>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="No. of Shortlist Course">
                        <b v-if="applicationData.shortListedColleges !== undefined">
                            {{applicationData.shortListedColleges.length}}
                        </b>
                        <b v-else>-</b>
                    </b-form-group>
                </b-col>
                 <b-col>
                    <b-form-group label="Status">
                        <span>                                       
                            <!-- <b-form-select
                            v-model="selectedStatus"
                            value-field="value" 
                            text-field="text"
                            :options="statusArry"
                            placeholder="Enter Text"
                            @change="updateSuggestStatus" 
                            :disabled='isSppiner'
                            />    -->
                            <b-form-group>
                                <b-dropdown
                                    id="status-dropdown-1"
                                    class="status_dropdown"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    :text="selectedStatus"
                                    :variant="applicationStatusVariant"
                                    :class="[{'pendingCSSSClass':selectedStatus === 'Payment Pending','applicationSubmitted' : selectedStatus === 'Application Submitted','applicationNotEligible' : selectedStatus === 'Not Eligible'}]"
                                    v-if="$route.meta.action=='write' || $route.meta.action==undefined"
                                >
                                    <span v-for="(stat,indexes) in statusArry" :key='indexes'>
                                        <b-dropdown-item @click.prevent="updateSuggestStatus(stat)" :variant="stat.variant" :class="[{'applicationNotEligibleText' : selectedStatus === 'Not Eligible'}]">{{stat.text}}</b-dropdown-item>
                                    </span>
                                </b-dropdown>
                                <b-badge
                                    pill
                                    v-else
                                    :variant="'light-'+applicationStatusVariant"
                                >
                                    {{selectedStatus}}
                                </b-badge>
                            </b-form-group>
                        </span>  
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        
        <b-card>
            <b-col md="12" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                <div class="content-header-right text-md-right d-md-block d-none mb-1 top_btn_right">
                    <b-button v-if="$route.meta.resource!='EAC'" @click="!isLoading ? $router.push({ name: 'application-mgmt-Edit', params:{name:$route.params.name,id:$route.params.id},query:{isCourseEdit:true} }) : ''" :variant="!isLoading ? 'primary' : 'secondary'" class="btn btn-icon mr-10">
                        Suggest New Course
                    </b-button>
                    <b-button @click="!isLoading ? saveShorlistedColleges() : ''" v-if="$route.meta.resource!='EAC'" :variant="!isLoading ? 'primary' : 'secondary'" class="btn btn-icon">
                        Move to Shortlist
                    </b-button>
                </div>
            </b-col>
            <b-tabs content-class="mt-3"> 
                <!-- SUGGESTED LIST              -->
                <b-tab title="Suggested Course" v-if="$route.meta.resource!='EAC'">
                    <div>
                        <b-row>                       
                        <b-col
                        md="3"
                        >
                       <b-form-group label="College">
                        <v-select
                            :value="collegeFilter"
                            v-model="collegeFilter"
                            :options="collegeOptions"
                            class="w-100"
                            placeholder="All"
                        />
                         </b-form-group>
                        </b-col>
                        <b-col
                        md="3"
                        >
                       
                          <b-form-group label="University">
                        <v-select
                            :value="universityFilter"
                            v-model="universityFilter"
                            :options="universityOptions"
                            class="w-100"
                            :reduce="val => val.value" 
                            placeholder="All"                        
                        />
                         </b-form-group>
                        </b-col>
                        <b-col
                        md="3"
                        >
                         <b-form-group label="Field">
                        <v-select
                            :value="fieldFilter"
                            v-model="fieldFilter"
                            :options="fieldOptions"
                            class="w-100" 
                            placeholder="All"                                                   
                        />
                         </b-form-group>
                        </b-col>
                        <b-col
                            md="3"
                            >
                            <b-form-group label="Course">
                            <v-select
                                :value="courseFilter"
                                v-model="courseFilter"
                                :options="courseOptions"
                                class="w-100" 
                                placeholder="All"                                                   
                            />
                             </b-form-group>
                        </b-col>
                        <b-col
                            md="3"
                            >
                            <b-form-group label="Intake">
                            
                            <v-select
                                :value="intakeFilter"
                                v-model="intakeFilter"
                                :options="intakeOptions"
                                class="w-100"  
                                placeholder="All"                                                  
                            />
                            </b-form-group>
                        </b-col>
                        <b-col
                        md="3"
                        >
                             <b-form-group label="Exam">
                            <v-select
                                :value="examFilter"
                                v-model="examFilter"
                                :options="examOptions"
                                class="w-100"   
                                placeholder="All"                                                 
                            />
                            </b-form-group>
                        </b-col>
                        <b-col
                        md="3"
                        >
                            <b-form-group label="Score">
                            <v-select
                                :value="bandScore"
                                v-model="bandScore"
                                :options="bandScoreOptions"
                                class="w-100"
                                placeholder="All"
                            />
                            </b-form-group>
                        </b-col>
                        <b-col
                        md="3"
                        >
                            <b-form-group label="Mode Of Application">
                            <v-select
                                :value="modeOfApp"
                                v-model="modeOfApp"
                                :options="modeOfAppOptions"
                                class="w-100"
                                placeholder="All"
                            />
                            </b-form-group>
                        </b-col>
                        </b-row>
                    </div>
                    <hr class="custom_hr">
                    <div>  
                        <!-- Table Container Card -->
                        <b-card
                        no-body
                        class="mb-0"
                        >
                        <div>

                        </div>
                        <div>
                            <!-- Table Top -->
                            <b-row>
                            <b-col md="6" class="d-flex align-items-center justify-content-start"> 
                                <h4 class="mb-0 table_title">College</h4>
                            </b-col> 
                                 
                            <!-- Search -->
                            <b-col
                                cols="6"
                                md="6"
                            >
                                <div class="d-flex align-items-center justify-content-end">
                                    <v-select
                                    v-model="perPage"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="perPageOptions"
                                    :clearable="false"
                                    class="per-page-selector d-inline-block mx-50"
                                    />                                
                                </div>
                            </b-col>

                            <b-table
                                ref="refUserListTable"
                                class="position-relative mt-2"
                                :current-page="currentPage"                                
                                :items="filterList"
                                :per-page="perPage"
                                responsive
                                v-model="suggestedCllgTable"
                                :fields="suggesteCllgtableColumns"       
                                show-empty
                                empty-text="No matching records found"                        
                            >   
                                <!-- <template #head(isSelect)="">
                                    <input type="checkbox" v-if="!applicationData.applicationClose && !checkAllClgStatuses()" value="true" v-model="allSelect" @change="selectAll()"/>
                                </template> -->
                                <template #cell(isSelect)="data">
                                    <input type="checkbox" value='true' v-if="!applicationData.applicationClose && data.item.clgStatus=='pending' || data.item.clgStatus==''" @change="suggestedChange()" v-model="data.item.isSelect"/>
                                    <img 
                                        src="@/assets/images/erflog/info_icon.png"
                                        alt="info"
                                        v-else
                                        v-b-tooltip.hover.top="'Application is in progress.'"
                                    />
                                </template>

                                <!-- Column: Intake -->
                                <template #cell(intake)="data">
                                    <span v-for="(int,intIndex) in data.item.intake" :key="'intake-'+intIndex">
                                        {{int}}<br>
                                    </span>
                                </template> 
                                <!-- Column: DEADLINE DATE -->
                                <template #cell(applicationDeadline)="data">
                                    <span v-for="(int,intIndex) in data.item.applicationDeadline" :key="'deadline-'+intIndex">
                                        {{int.DispDate}}<br>
                                    </span>
                                </template>
                                <!-- Column: EXAMS & SCORES -->
                                <template #cell(examsAndScore)="data">
                                    <span v-for="(int,intIndex) in data.item.examsAndScore" :key="'exams-'+intIndex">
                                        {{int.title}}&nbsp;&nbsp;{{int.score}}<br>
                                    </span>
                                </template>  
                                <template #cell(action)="data">
                                    <img src='@/assets/images/erflog/Delete.png' v-if="applicationData.selectedCollege.length > 1 && !data.item.isSelect && (!applicationData.shortListedColleges || applicationData.shortListedColleges.findIndex((e)=>{return e.courseUniqueId == data.item.courseUniqueId}) == -1)" style="cursor: pointer;" @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/>
                                </template>  
                                <template #cell(remark)="data">
                                    <span v-if="data.item.remark" :title="data.item.remark" class="remarktext__elisis">{{data.item.remark}}</span>
                                    <span v-else>--</span>
                                </template>  
                                <template #cell(germanLanguage)="data">
                                    <span v-if="data.item.germanLanguage" :title="data.item.germanLanguage" class="remarktext__elisis">{{data.item.germanLanguage}}</span>
                                    <span v-else>--</span>
                                </template>
                            </b-table>
                           

                            <b-col>
                            <div class="mb-2">
                                <b-row>
                                 <b-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                >
                                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                                </b-col>    
                                <!-- Pagination -->
                                <b-col
                                    md="12"
                                    sm="12"
                                    class="d-flex align-items-center justify-content-md-end"
                                >

                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="filterList.length"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    >
                                    <template #prev-text>
                                        <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                        />
                                    </template>
                                    </b-pagination>

                                </b-col>

                                </b-row>
                            </div>
                            </b-col>

                            </b-row>
                        </div>
                        </b-card>
                    </div>
                </b-tab>
                <!-- Shorlist Course LIST -->
                <b-tab title="Shortlist Course">
                    <div>
                        <b-table
                            ref="refUserListTable"
                            class="position-relative application_detail_table sortlisttable"
                            :items="shortListedCollege"
                            responsive
                            :fields="shortlistedColumn"              
                            show-empty
                            empty-text="No matching records found"                        
                        >  
                            <template #cell(isSelect)="data">
                                <img 
                                    src="@/assets/images/erflog/history_Log.svg"
                                    alt="info"
                                    style="cursor: pointer;"
                                    @click="showShortlistAcivityInfo(data.item),$bvModal.show('activity-log-history')"
                                    v-b-tooltip.hover.top="'You can see the college’s activities by clicking here.'"
                                />
                                <!-- <img 
                                    src="@/assets/images/erflog/info_icon.png" 
                                    alt="info"
                                    @click="showShortlistAcivityInfo(data.item,true)"
                                /> -->
                            </template>
                            <template #cell(isSelectCollege)="data">
                                <img src='@/assets/images/erflog/Approved.png' style="margin-right: 5px;" v-if="applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId"/>
                            </template>
                            <!-- Column: cllgName DATE -->
                            <template #cell(cllgName)="data">
                                <div style="display: flex;align-items: center;">
                                    <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                        {{data.item.cllgName}}<br>
                                    </span>
                                </div>
                            </template>
                            <!-- Column: uniName -->
                            <template #cell(uniName)="data">
                                <div style="display: flex;align-items: center;">
                                    <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                        {{data.item.uniName}}<br>
                                    </span>
                                </div>
                            </template>
                            <!-- Column: uniName -->
                            <template #cell(courseName)="data">
                                <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{data.item.courseName}}<br>
                                </span>
                            </template>
                            <template #cell(fieldName)="data">
                                <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{data.item.fieldName}}<br>
                                </span>
                            </template>
                            <!-- Column: uniName -->
                            <template #cell(applicationFee)="data">
                                <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{data.item.applicationFee}}<br>
                                </span>
                            </template>
                            <!-- Column: uniName -->
                            <template #cell(tutionFee)="data">
                                <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{data.item.tutionFee}}<br>
                                </span>
                            </template>
                            <!-- Column: uniName -->
                            <template #cell(sortListDate)="data">
                                <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{data.item.sortListDate}}<br>
                                </span>
                            </template>
                            <!-- Column: uniName -->
                            <template #cell(modeOfApplication)="data">
                                <span :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{data.item.modeOfApplication}}<br>
                                </span>
                            </template>
                            <!-- Column: EXAMS & SCORES -->
                            <template #cell(examsAndScore)="data">
                                <span v-for="(int,intIndex) in data.item.examsAndScore" :key="'exm-'+intIndex" :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{int.title}}&nbsp;&nbsp;{{int.score}}<br>
                                </span>
                            </template>  
                            <template #cell(applicationDeadline)="data">
                                <span v-for="(int,intIndex) in data.item.applicationDeadline" :key="'appdeadline-'+intIndex" :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}">
                                    {{int.DispDate}}<br>
                                </span>
                            </template>
                            <template #cell(remark)="data">
                                <span v-if="data.item.remark" :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}" :title="data.item.remark" class="remarktext__elisis">{{data.item.remark}}</span>
                                <span v-else>--</span>
                            </template>  
                            <template #cell(germanLanguage)="data">
                                <span v-if="data.item.germanLanguage" :class="{addUniqClass: applicationData.finalSelected && data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId}" :title="data.item.germanLanguage" class="remarktext__elisis">{{data.item.germanLanguage}}</span>
                                <span v-else>--</span>
                            </template>
                            <!-- Column: EXAMS & SCORES -->
                            <!-- <template #cell(examsAndScore)="data">
                                <span v-for="(int,intIndex) in data.item.examsAndScore" :key="'exm-'+intIndex">
                                    {{int.title}}&nbsp;&nbsp;{{int.score}}<br>
                                </span>
                            </template>   -->
                             <!-- Column: APPLICATION STATUS-->
                            <template #cell(shortlistStatus)="data">
                                <!-- <span> -->
                                <!-- <b-form-select
                                v-model="data.item.shortlistStatus"
                                value-field="value" 
                                text-field="text"
                                :options="shortlistStatusArry"
                                placeholder="Enter Text"
                                :disabled='isSppiner'
                                @change="updateShortListStatus(data.item)"
                                /> -->
                                <!-- {{data.item}} -->
                                <!-- <b-badge :class="data.item.statusVariant"> -->
                                <!-- <b-col md="4" lg="3" > -->
                                <b-form-group>
                                    <b-dropdown
                                        id="status-dropdown-1"
                                        class="status_dropdown"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        :text="shortlistStatusArry.filter(x => x.value === data.item.shortlistStatus)[0].text"
                                        :variant="data.item.statusVariant"
                                        :class="[{'pendingCSSSClass':shortlistStatusArry.filter(x => x.value === data.item.shortlistStatus)[0].text === 'Payment Pending','applicationSubmitted' : shortlistStatusArry.filter(x => x.value === data.item.shortlistStatus)[0].text === 'Application Submitted','applicationNotEligible' : shortlistStatusArry.filter(x => x.value === data.item.shortlistStatus)[0].text === 'Not Eligible'}]"
                                        v-if="$route.meta.action=='write' || $route.meta.action==undefined"
                                    >
                                        <span v-for="(stat,indexes) in shortlistStatusArry" :key='indexes'>
                                            <b-dropdown-item @click.prevent="updateShortListStatus(data.item,stat)" :variant="stat.variant" :class="[{'applicationNotEligibleText' : selectedStatus === 'Not Eligible'}]">{{stat.text}}</b-dropdown-item>
                                        </span>
                                    </b-dropdown>
                                    <b-badge
                                        pill
                                        v-else
                                        :variant="'light-'+data.item.statusVariant"
                                    >
                                        {{shortlistStatusArry.filter(x => x.value === data.item.shortlistStatus)[0].text}}
                                    </b-badge>
                                </b-form-group>
                                <!-- </b-col> -->
                                <!-- </b-badge> -->
                                <!-- </span>                                -->
                            </template>
                            
                            <!-- uploadPayFile -->
                            <template #cell(pendingPay)="data">
                                <div v-if="applicationData.applicationClose!=undefined && applicationData.applicationClose==true">
                                    <span v-if="data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId">
                                        <a href='#'>Selected</a>
                                    </span>
                                    <!-- <span v-if="!(data.item.shortlistStatus == 'admissionSuccessful' && data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId)" style="margin-left: 5px;">                                    -->
                                    <span v-if="$route.meta.resource == undefined && data.item.shortlistStatus == 'admissionSuccessful' && !(data.item.courseUniqueId == applicationData.finalSelected.courseUniqueId)">                                   
                                        <b-button class="btn btn-icon btn-outline-primary" @click.prevent="()=>{finalSelectedCollege=data; $bvModal.show('modal-select-college');}">Select</b-button>
                                    </span>
                                </div>
                                <div>
                                    <span v-if='data.item.pendingPaymentFile !== undefined && data.item.shortlistStatus=="paymentSuccessful"'>                                  
                                        <img src='@/assets/images/erflog/Download.png' style="cursor: pointer;" @click.prevent="downloadFile([data.item.pendingPaymentFile])" class="action_icon"/>
                                        <!-- <img src='@/assets/images/erflog/Download.png' style="cursor: pointer;" v-if="$route.meta.action=='write' || $route.meta.action==undefined"  @click.prevent="downloadFile([data.item.pendingPaymentFile])" class="action_icon"/> -->
                                        <!-- <span v-else style="white-space: nowrap">
                                            --
                                        </span> -->
                                    </span>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
        <!-- <div v-if='isSppiner' class="loading"></div> -->
        <div v-if="isSppiner" class="loading loading-custom">         
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>     
        </div>

        <!--Confirmation of Shortlist-College Status Payment-Pending -->
        <b-modal
            id="modal-Payment-Pending-confirm"
            cancel-variant="outline-secondary"
            ok-title="Yes"
            cancel-title="No"
            centered
            title=""
            v-model="paymentPendingConfirm"
            :no-close-on-backdrop="true"
            @ok='paymentPendingNotes=true'
            @hidden="paymentPendingConfirm=false"
        >       
        <div style="text-align: center;">
            <label class="s_modal_title">Are you sure?</label>
            <p><span>Do you want to change application status Payment Pending ?</span></p>
        </div>
        </b-modal>
        <!--select college -->
        <b-modal
            id="modal-select-college"
            cancel-variant="outline-secondary"
            ok-title="Yes"
            cancel-title="No"
            centered
            title=""
            :no-close-on-backdrop="true"
            @hidden="finalSelectedCollege=''"
            @ok="(e)=>{e.preventDefault(); selectCollegeForAdmission()}"
        >
        <div class="create_account_img">
        <img src="@/assets/images/erflog/Approve_Popup.png" alt="student">
        </div>
        <div style="text-align: center;">
            <label class="s_modal_title">Are you sure?</label>
            <p><span>Do you want to Select this University?</span></p>
        </div>
        </b-modal>

        <!-- ADD SHORTLIST IN PAYMENT PENDING -->
        <b-modal
        id="modal-Payment-Pending-Notes"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        title="Payment-pending description"
        v-model="paymentPendingNotes"
        :no-close-on-backdrop="true"
        @ok="(e)=>{e.preventDefault();NotesCollegePaymentPending();}"
        @hidden="paymentPendingNotes=false,payPenddingObject=null,pendingNotes = ''"
        >       
        <div>
            
            <span>
                <validation-observer ref="paymentPendingNotes">
                <form>                  
                    <b-row>
                        <!-- <b-col sm='3'>
                            <label for="exampleFormControlTextarea4">Add Note</label>
                        </b-col> -->
                        <b-col md='12'>
                            <b-form-group label="Add Text">
                            <validation-provider
                            #default="{ errors }"
                            name="text"
                            rules="required"
                            >                                
                                <b-form-textarea
                                    v-model="pendingNotes" 
                                    name="payment_pendingText" 
                                    :state="errors.length > 0 ? false:null" 
                                    rows="5"
                                    no-resize
                                >
                                </b-form-textarea>                              
                                <small class="text-danger">{{ errors[0] }}</small>                               
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>
                </validation-observer>
            </span>
        </div>
        </b-modal>

        <!--Confirmation of Shortlist-College Status Payment-Pending -->
        <b-modal
            id="activity-log-history"
            centered
            :no-close-on-backdrop="true"
            hide-footer
            @hidden="activityArray = [],lastValue = null"
        >       
        <div>
            <label class="s_modal_title" style="text-align: center;">Log</label>
            <div class="table-data" v-if="activityArray.length > 0">
                <div class="tableBody">
                    <div v-for="(log,index) in activityArray" :key="index" style="padding: 5px 0;border-bottom: 1px solid #e5e5e5;">
                        <div style="display: flex;align-items: center;">
                            <!-- <div style="margin-right: 10px;">
                                <img style="height: 25px;width: 25px;border-radius: 25px;" :src="log.avatar ? log.avatar : 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/DefaultUser%2FDefaultUser.png?alt=media&token=1b92ac0f-1395-4062-9aa2-59f126ccf5e9'" alt="">
                            </div> -->
                            <div>
                                <span style="font-size: 14px;" v-html="log.message"></span>.<br/>
                                <span style="font-size: 12px;">{{ displayDateWithFormat(log.createdAt) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="showLoadMoreButton">
                        <b-button variant="primary" class="btn btn-icon btn-primary" style="margin-top: 15px;" @click="showShortlistAcivityInfo(selectedColege,true)">Load More</b-button>
                    </div>
                </div>
            </div>
            <div v-else style="text-align: center;font-size: 16px;">
                No log found
            </div>
        </div>
        </b-modal>
        <b-modal
            id="delete-field"
            cancel-variant="outline-secondary"
            ok-title="Yes"
            cancel-title="No"
            centered
            title=""
            :no-close-on-backdrop="true"
            @hidden="selectedRemoveData=''"
            v-if="selectedRemoveData!=''"
            @cancel='isSpiner=false'
            @ok="deleteField(selectedRemoveData)"
        >
            <div class="create_account_img">
            <img src="@/assets/images/erflog/DeletePopup.png" alt="">
            </div>
            <div style="text-align: center;" v-if="selectedRemoveData!=''">
            <label class="s_modal_title">Remove Suggested College</label>
            <p>Are you sure you want to remove these suggested college?</p>
            </div>
        </b-modal>
    </div>
</template>
<script>
import {
  BCard, 
  BFormGroup,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BTable,
  BPagination,
  BCardHeader,
  BCardBody,
  BBadge,
  BFormSelect,
  BFormTextarea,
  BFormInput,
  VBTooltip,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { dbCollections } from '@/utils/firebaseCollection'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import vSelect from 'vue-select'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import firebase from 'firebase'
import {addHistoryObjectToApplication} from '@/utils/addHistoryOfApplication.js'
import firebaseApp from "@/utils/firebaseInit.js";
import Button from '@/views/components/button/Button.vue'
import firebaseTimeStemp from 'firebase'
const db = firebaseApp.firestore()
extend('required',required)
// import useUsersList from './applicationDetaile.js'
export default{
    directives: {
        Ripple,
        'b-tooltip':VBTooltip
    },
    components:{
        ValidationProvider,
        ValidationObserver,
        BCard,
        BFormGroup,
        BFormTextarea,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BTabs,
        BTab,
        BTable,
        vSelect,
        BPagination,
        BCardHeader,
        BCardBody,
        BFormSelect,
        BFormInput,
        BBadge,
        BButton,
        Button,
        BSpinner
        // applicationFilters
    },
    data(){
        return{
            isLoading: true,
            savingAdmission: false,
            applicationData:{},
            applicationDataInitial:{},
            selectedRemoveData:{},
            studentData:{},
            statusArry:[
                {text:'Pending',value:'pending',variant:'primary',type:'applicationPending'},
                {text:'Not Eligible',value:'notEligible',variant:'Dark',type:'applicationNotEligible'},
                {text:'In Progress',value:'inProgress',variant:'warning',type:'applicationInprogress'},
                {text:'Payment Pending',value:'paymentPending',variant:'secondary',type:'paymentPending'},
                {text:'Payment Successful',value:'paymentSuccessful',variant:'info',type:'paymentSuccessful'},
                {text:'Application Submitted',value:'applicationSubmitted',variant:'Dark',type:'applicationSubmitted'},
                {text:'Admission Offered',value:'admissionSuccessful',variant:'success',type:'admissionOffered'},
                {text:'Rejection',value:'admissionRejected',variant:'danger',type:'applicationReject'}
            ],
            sortListOrder:['pending','notEligible','inProgress','paymentPending','paymentSuccessful','applicationSubmitted','admissionSuccessful','admissionRejected'],
            shortlistStatusArry:[
                {text:'Pending',value:'pending',variant:'primary', type:'applicationDetailPending'},
                {text:'Not Eligible',value:'notEligible',variant:'Dark', type:'applicationNotEligible'},
                {text:'In Progress',value:'inProgress',variant:'warning', type:'applicationDetailInProgress'},
                {text:'Payment Pending',value:'paymentPending',variant:'secondary', type: 'applicationDetailPaymentPending'},
                {text:'Payment Successful',value:'paymentSuccessful',variant:'info', type: 'applicationDetailPaymentSuccessful'},
                {text:'Application Submitted',value:'applicationSubmitted',variant:'Dark', type:'applicationDetailApplicationSubmitted'},
                {text:'Admission Offered',value:'admissionSuccessful',variant:'success', type: 'applicationDetailAdmissionOffered'},
                {text:'Rejection',value:'admissionRejected',variant:'danger', type: 'applicationDetailRejection'}
            ],
            selectedshortlistStatus:'',    
            selectedStatus:'',
            applicationStatusVariant:'',

            allSuggestedCollege:[],
            allShortlistedCollege:[],
            suggestedColleges:[],
            tempSuggestColleg:[],
            perPage: 10,
            totalUsers: 0,
            currentPage: 1,
            perPageOptions: [10,25,50,100],
            suggesteCllgtableColumns:[
                {key:'isSelect',label:''},
                // {key:'no',label:'No.'},
                // {key:'cllgName',label:'College Name', sortable: true},
                // {key:'uniName',label:'University Name', sortable: true},
                // {key:'fieldName',label:'Field Name', sortable: true},
                // {key:'courseName',label:'Course Name', sortable: true},
                // {key:'modeOfApplication',label:'Mode of Application', sortable: true},
                // {key:'intake',label:'Intake', sortable: true},
                // {key:'applicationFee',label:'application Fee', sortable: true},
                // {key:'tutionFee',label:'Tution Fee', sortable: true},
                // {key:'applicationDeadline',label:'Application DeadLine', sortable: true},
                // {key:'examsAndScore',label:'English Scores', sortable: true}
            ],
            collegeFilter: null,
            collegeOptions:[],
            selectedCollegeId:[],
            universityFilter:null,
            universityOptions:[],
            fieldFilter:null,
            fieldOptions:[],
            allColleges:[],
            courseFilter:null,
            courseOptions:[],
            intakeFilter:null,
            intakeOptions:[],
            examFilter:null,
            examOptions:[],
            isSppiner:false,
            shortListedCollege:[],
            shortlistedColumn:[
                // {key:'no',label:'No.'},
                // {key:'cllgName',label:'College Name', sortable: true},
                // {key:'uniName',label:'University Name', sortable: true},
                // {key:'courseName',label:'Course Name', sortable: true},
                // {key:'modeOfApplication',label:'Mode of Application', sortable: true},
                // {key:'applicationDeadline',label:'Application DeadLine', sortable: true},
                // {key:'examsAndScore',label:'English Scores', sortable: true},
                // {key:'applicationFee',label:'application Fee', sortable: true},
                // {key:'tutionFee',label:'Tution Fee', sortable: true},
                // {key:'shortlistStatus',label:'Application Status', sortable: true},
                // {key:'pendingPay',label:''}
            ],
            paymentPendingConfirm: false,
            paymentPendingNotes: false,
            payPenddingObject:null, //payment pending in shortlist object
            paymentPendingStatus:null,//payment pending status
            pendingNotes:'',
            suggestedCllgTable:[],

            allSelect:false,


            bandScore:null,
            bandScoreOptions:[
                {label:4,value:"4"},
                {label:4.5,value:"4.5"},
                {label:5,value:"5"},
                {label:5.5,value:"5.5"},
                {label:6,value:"6"},
                {label:6.5,value:"6.5"},
                {label:7,value:"7"},
                {label:7.5,value:"7.5"},
                {label:8,value:"8"},
                {label:8.5,value:"8.5"},
                {label:9,value:"9"}
            ],
            modeOfApp:null,
            modeOfAppOptions:[
                {label:'Direct',value:'direct'},
                {label:'Uniassist',value:'uniassist'}
            ],
            previousShortListedCollege:[],
            lastValue:null,
            activityArray:[],
            selectedColege:{},
            showLoadMoreButton:true,
            finalSelectedCollege:'',
            addmissionSelected:null,
        }
    }, 
    watch: {
        $route: function () { 
            // console.log("----------------------------- route change -----------------------------")
            // this.init()    
            this.getData();      
        }
    },   
    created() {
        var self = this

        if(self.$route.meta.resource!="EAC")
        {
            self.suggesteCllgtableColumns.push({key:'cllgName',label:'College Name', sortable: true})
            self.suggesteCllgtableColumns.push({key:'uniName',label:'University Name', sortable: true})
            self.suggesteCllgtableColumns.push({key:'fieldName',label:'Field Name', sortable: true})
            self.suggesteCllgtableColumns.push({key:'courseName',label:'Course Name', sortable: true})
            self.suggesteCllgtableColumns.push({key:'modeOfApplication',label:'Mode of Application', sortable: true})
            self.suggesteCllgtableColumns.push({key:'intake',label:'Intake', sortable: true})

            self.suggesteCllgtableColumns.push({key:'applicationFee',label:'application Fee', sortable: true})
            self.suggesteCllgtableColumns.push({key:'tutionFee',label:'Tution Fee', sortable: true})
            self.suggesteCllgtableColumns.push({key:'applicationDeadline',label:'Application DeadLine', sortable: true})
            self.suggesteCllgtableColumns.push({key:'germanLanguage',label:'German Language', sortable: true})
            self.suggesteCllgtableColumns.push({key:'examsAndScore',label:'English Scores', sortable: true})
            self.suggesteCllgtableColumns.push({key:'remark',label:'Remark', sortable: true})
            self.suggesteCllgtableColumns.push({key:'action',label:'Action'})


            self.shortlistedColumn.push({key:'isSelectCollege',label:''})
            self.shortlistedColumn.push({key:'cllgName',label:'College Name', sortable: true})
            self.shortlistedColumn.push({key:'uniName',label:'University Name', sortable: true})
            self.shortlistedColumn.push({key:'courseName',label:'Course Name', sortable: true})
            self.shortlistedColumn.push({key:'fieldName',label:'Field Name', sortable: true})
            self.shortlistedColumn.push({key:'modeOfApplication',label:'Mode of Application', sortable: true})
            self.shortlistedColumn.push({key:'applicationDeadline',label:'Application DeadLine', sortable: true})
            self.shortlistedColumn.push({key:'germanLanguage',label:'German Language', sortable: true})
            self.shortlistedColumn.push({key:'examsAndScore',label:'English Scores', sortable: true})
            self.shortlistedColumn.push({key:'remark',label:'Remark', sortable: true})

            self.shortlistedColumn.push({key:'applicationFee',label:'application Fee', sortable: true})
            self.shortlistedColumn.push({key:'tutionFee',label:'Tution Fee', sortable: true})
            self.shortlistedColumn.push({key:'shortlistStatus',label:'Application Status', sortable: true})
            self.shortlistedColumn.push({key:'pendingPay',label:''})
            self.shortlistedColumn.push({key:'sortListDate',label:'Sort List Date'})
            self.shortlistedColumn.push({key:'isSelect',label:'Log'})
        }
        else
        {
            self.shortlistedColumn.push({key:'isSelectCollege',label:''})
            self.suggesteCllgtableColumns.push({key:'uniName',label:'University Name', sortable: true})
            self.suggesteCllgtableColumns.push({key:'courseName',label:'Course Name', sortable: true})
            self.suggesteCllgtableColumns.push({key:'modeOfApplication',label:'Mode of Application', sortable: true})
            self.suggesteCllgtableColumns.push({key:'intake',label:'Intake', sortable: true})

            self.suggesteCllgtableColumns.push({key:'applicationDeadline',label:'Application DeadLine', sortable: true})
            self.suggesteCllgtableColumns.push({key:'examsAndScore',label:'English Scores', sortable: true})


            self.shortlistedColumn.push({key:'uniName',label:'University Name', sortable: true})
            self.shortlistedColumn.push({key:'courseName',label:'Course Name', sortable: true})
            self.shortlistedColumn.push({key:'modeOfApplication',label:'Mode of Application', sortable: true})
            self.shortlistedColumn.push({key:'applicationDeadline',label:'Application DeadLine', sortable: true})
            self.shortlistedColumn.push({key:'germanLanguage',label:'German Language', sortable: true})
            self.shortlistedColumn.push({key:'examsAndScore',label:'English Scores', sortable: true})
            self.shortlistedColumn.push({key:'remark',label:'Remark', sortable: true})

            self.shortlistedColumn.push({key:'shortlistStatus',label:'Application Status', sortable: true})
            self.shortlistedColumn.push({key:'pendingPay',label:''})
            if(self.$route.meta.action == 'write') {
                self.shortlistedColumn.push({key:'isSelect',label:'Log'})
            }
        }

        setTimeout(() => {
            self.$root.$emit('startSpinner');
        });

        // if(self.$route.meta.resource!="EAC")
        // {
        //     self.suggesteCllgtableColumns.push({key:'applicationFee',label:'Application Fee', sortable: true})
        //     self.suggesteCllgtableColumns.push({key:'tutionFee',label:'Tution Fee', sortable: true})
        //     self.suggesteCllgtableColumns.push({key:'applicationDeadLine',label:'Application DeadLine', sortable: true})
        //     self.suggesteCllgtableColumns.push({key:'examsScores',label:'English Scores', sortable: true})

        //     self.shortlistedColumn.push({key:'applicationFee',label:'Application Fee', sortable: true})
        //     self.shortlistedColumn.push({key:'tutionFee',label:'Tution Fee', sortable: true})
        //     self.shortlistedColumn.push({key:'shortlistStatus',label:'Application Status', sortable: true})
        //     self.shortlistedColumn.push({key:'pendingPay',label:''})
        // }
        // else
        // {
        //     self.suggesteCllgtableColumns.push({key:'applicationDeadLine',label:'Application DeadLine', sortable: true})
        //     self.suggesteCllgtableColumns.push({key:'examsScores',label:'English Scores', sortable: true})

        //     self.shortlistedColumn.push({key:'shortlistStatus',label:'Application Status', sortable: true})
        //     self.shortlistedColumn.push({key:'pendingPay',label:''})
        // }

        // self.init()
        // console.log("route change-----------------------------FROM COMPONENT CREATED")
        self.getData();

        self.$root.$on('featchCollegeFilter',(val)=>{
            let findInd = self.collegeOptions.findIndex(val=>{
                return val.value == val;
            })
            if(findInd > -1){
            self.collegeFilter = self.collegeOptions[findInd].label;
            }
        })
    },  
    // setup() {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    // // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })
    // const {
    //     suggestedColleges,
    // } = useUsersList()
    // return{
    //   suggestedColleges,  
    // }
    // },  
    methods:{
        deleteField() {
            try {
                let finalSelectedObj = JSON.parse(JSON.stringify(this.applicationDataInitial.selectedCollege))
                let finalSelectedIndex = JSON.parse(JSON.stringify(this.applicationDataInitial.selectedCollege)).findIndex((e)=>{return e.courseUniqueId == this.selectedRemoveData.item.courseUniqueId});
                let selectedCollege = JSON.parse(JSON.stringify(this.applicationDataInitial.selectedCollege)).find((e)=>{return e.courseUniqueId == this.selectedRemoveData.item.courseUniqueId});
                if(finalSelectedIndex !== -1) {
                    finalSelectedObj.splice(finalSelectedIndex,1)
                }
                db
                .collection(dbCollections.APPLICATIONS)
                .doc(this.$route.params.id)
                .update({
                    'selectedCollege':finalSelectedObj,
                    'updatedAt': firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                })
                .then(() =>{
                    let obj = {...selectedCollege};
                    db.collection(dbCollections.APPLICATIONS).doc(this.$route.params.id).collection("application_history")
                    .add({
                        createdAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                        userId: JSON.parse(localStorage.getItem('userData'))?.id,
                        userName: JSON.parse(localStorage.getItem('userData'))?.fullName,
                        fromApllicationDetail:true,
                        removed:[obj]
                    })
                    .catch((error) => {
                        console.error("ERROR: ", error);
                    })
                    this.$root.$emit('showToastMessage','Application Updated Successfully.','success')
                })
            } catch (error) {
                this.$root.$emit('showToastMessage','Some thing went wronge.','danger')
                console.error(error)
            }
        },
        selectCollegeForAdmission()
        {
            var self = this
            if(self.savingAdmission) return;
            self.savingAdmission = true;
            self.selection = true;
            self.addmissionSelected = self.finalSelectedCollege;
            let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
            let finalSelectedObj = {...self.addmissionSelected.item,'selectedTime':firebase.firestore.FieldValue.serverTimestamp()};
            db
            .collection(dbCollections.APPLICATIONS)
            .doc(this.applicationData.id)
            .update({
                'finalSelected':finalSelectedObj,
                'applicationClose':true,
                'updatedAt': firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
            })
            .then(() =>{                   
                self.$root.$emit('showToastMessage','Application selected successfully.','success') 
                    db
                    .collection(dbCollections.USERS)
                    .doc(this.applicationData.selectedStudent.id)
                    .update({
                        updatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                        selectedCollege:self.finalSelectedCollege.item.cllgName,
                        selectedCollegeId:self.finalSelectedCollege.item.cllgId,
                        selectedFieldId:self.finalSelectedCollege.item.fieldId,
                        selectedCourseId:self.finalSelectedCollege.item.courseCode,
                    })
                    .then(() =>{
                        db.collection(dbCollections.USERS).doc(this.applicationData.selectedStudent.id).get().then((user)=>{
                            let notificationObj = {
                                    name: user.data().firstName,
                                    imgUrl: user.data().imageUrl,
                                    selectedUniversity: self.addmissionSelected.item.cllgName,
                                    userId: user.data().id
                            }
                            let object = {
                                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                                message: `<span><b>${currentUserObject.fullName}</b> has selected <b>${self.addmissionSelected.item.cllgName}</b> for admission</span>`,
                                userId: firebase.auth().currentUser.uid,
                                fID: self.finalSelectedCollege.item.fieldId,
                                cId: self.finalSelectedCollege.item.cllgId,
                                courseCode: self.finalSelectedCollege.item.courseCode,
                                avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                            }
                            addHistoryObjectToApplication(object,self.addmissionSelected.item.application_Id).catch((error)=>{
                                console.error(error);
                            })
                            this.$axios.post(dbCollections.APIURL+'api/v1/admissionConfirmNotification',notificationObj).then(()=>{
                            }).catch((error)=>{
                                console.error(error);
                            })
                        }).catch((error) =>{console.error(error)});
                        self.$root.$emit('showToastMessage','University selected successfully.','success')
                        self.$bvModal.hide('modal-select-college');

                        self.$nextTick(() => {
                            self.savingAdmission = false;
                        })
                    })
                    .catch(error=>{
                        self.$root.$emit('showToastMessage',error.message,'danger')
                        console.log(error)
                    })
                })
                .catch(error=>{                    
                    console.log(error)
                })
        },
        displayDateWithFormat(historyObject) {
            return moment(historyObject.seconds * 1000).format('MMM DD, YYYY hh:mm A')
        },
        showShortlistAcivityInfo(dataObject,loadMore = false) {
            this.selectedColege = dataObject;
            let qureyRef = db
            .collection(dbCollections.APPLICATIONS)
            .doc(this.$route.params.id)
            .collection(dbCollections.HISTORY)
            .where('cId','==',this.selectedColege.cllgId)
            .where('fID','==',this.selectedColege.fieldId)
            .where('courseCode','==',this.selectedColege.courseCode)
            .orderBy('createdAt','desc')
            if(loadMore == false && this.lastValue == null) {
                this.activityArray = [];
                qureyRef = qureyRef.limit(15)
            } else {
                qureyRef = qureyRef.startAfter(this.lastValue).limit(15)
            }

            qureyRef
            .get()
            .then((res)=>{
                if(res.empty) {
                    this.lastValue = null;
                    this.showLoadMoreButton = false;
                    return;
                }
                res.docs.length < 15 ? this.showLoadMoreButton = false : this.showLoadMoreButton = true;
                res.forEach((doc)=>{
                    this.activityArray.push(doc.data())
                })
                this.lastValue = res.docs[res.docs.length-1];
            })
            .catch((err)=>{
                console.error(err)
            })
        },
        changeRoute(data){
            if(this.$route.meta.resource === 'ACL') {
                this.$router.push({name:'studentDetail'});
            } else {
                this.$router.push({name:'student-information',params:{name:data.name,id:data.id}});
            }
        },
        checkAllClgStatuses()
        {
            var self = this

            var found = false
    
            self.suggestedColleges.forEach(data=>{
                if(data.clgStatus!='pending' && data.clgStatus!='')
                {
                    found = true
                }
            })

            return found
        },
        selectAll(){
            if(this.allSelect !== false){
                if(this.suggestedColleges.length > 0){
                    this.suggestedColleges.forEach(ele=>{
                        ele.isSelect = true;
                    })
                    this.errorShortList = ""
                }
            }else{
                if(this.suggestedColleges.length > 0){
                    this.suggestedColleges.forEach(ele=>{
                        ele.isSelect = false;
                    })
                    this.errorShortList = 'please select some from Suggested Courses';
                }
            }
        },
        suggestedChange(){   
            let isValid = false
            let allChecked = true       
            if(this.suggestedColleges.length > 0){
                this.suggestedColleges.forEach(ele=>{
                    if(ele.isSelect == true){
                        isValid = true    
                    }else{
                        this.allSelect = false    
                    }
                    if(ele.isSelect !== true){
                        allChecked = false
                    }
                })
                if(allChecked == true){
                    this.allSelect = allChecked;
                }
                if(isValid == false){
                this.allSelect = false    
                this.errorShortList = 'please select some from Suggested Courses';
                }else{
                    this.errorShortList = '';
                }
                
            }
        },
        saveShorlistedColleges(){
            if(this.$route && this.$route.meta.resource && this.$route.meta.resource.toLowerCase() == 'eac' && this.$route.meta.action.toLowerCase() == 'write') {
                return;
            }
            let isValid = false
            let shortListArry = [];
            this.isSpinner = true;
            let currentUser = firebase.auth().currentUser;
            let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
            if(this.suggestedColleges.length > 0){
                    this.isSpinner = true;
                    this.suggestedColleges.forEach(ele=>{
                        if(ele.isSelect == true){
                            isValid = true
                        }
                    })
                    if(isValid == true){
                      let id = this.$route.params.id;
                      if(this.suggestedColleges.length > 0){
                        this.$axios.get(dbCollections.APIURL + 'api/v1/getTime')
                            .then(data => {
                                let updateVal = true;
                                let currentUtcString = data?.data?.utc_datetime ? data?.data?.utc_datetime : new Date().toISOString();
                                this.suggestedColleges.forEach(val=>{ //Suggested Course
                                    if(val.isSelect == true){
                                        this.allSuggestedCollege.forEach(elem=>{
                                            if(elem.courseUniqueId == val.courseUniqueId)
                                            {
                                                let findIndex = this.allShortlistedCollege.findIndex(dataArry=>{ //Shorlist Course
                                                    return elem.courseUniqueId == dataArry.courseUniqueId
                                                })
                                                if(findIndex > -1){
                                                    elem.status = this.allShortlistedCollege[findIndex].shortlist_status;
                                                    elem.statusText =  this.allShortlistedCollege[findIndex].statusText;
                                                    elem.statusVariant = this.allShortlistedCollege[findIndex].statusVariant;                                                              
                                                }else{
                                                    elem.status = 'pending';
                                                    elem.statusVariant = 'primary';
                                                    elem.statusText = 'Pending';
                                                }
                                                let index = this.previousShortListedCollege.findIndex((element)=>{
                                                    return (element.courseUniqueId == val.courseUniqueId)
                                                })
                                                if(index == -1){
                                                    elem.sortListDate = new Date(currentUtcString);
                                                } else {
                                                    elem.sortListDate = this.previousShortListedCollege[index].sortListDate !== '-' ? new Date(this.previousShortListedCollege[index].sortListDate) : '';
                                                }
                                                if(elem.sortListDate == 'Invalid Date') {
                                                    elem.sortListDate = new Date(currentUtcString);
                                                }
                                                
                                                //HISTORY OBJECT
                                                
                                                let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                                        return (element.courseUniqueId == val.courseUniqueId) && elem.courseUniqueId == element.courseUniqueId
                                                    })
                                                if(findIndex2 == -1){
                                                    let object = {
                                                        createdAt: new Date(currentUtcString),
                                                        message: `<span><b>${currentUserObject.fullName}</b> has added this to sortlist list</span>`,
                                                        userId: currentUser.uid,
                                                        fID: elem.fieldId,
                                                        cId: elem.id,
                                                        courseCode: elem.courseCode,
                                                        avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                                    }
                                                    // console.log("findIndex2--------------if---------",object,this.applicationData.id)
                                                    addHistoryObjectToApplication(object,this.applicationData.id).catch((error)=>{
                                                        console.error(error);
                                                    })
                                                    updateVal = false;
                                                }
                                                shortListArry.push(elem)  
                                            }
                                        })
                                    } else {
                                        let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                            return (element.courseUniqueId == val.courseUniqueId)
                                        })
                                        if(findIndex2 !== -1){
                                            let object = {
                                                createdAt: new Date(currentUtcString),
                                                message: `<span><b>${currentUserObject.fullName}</b> has removed this from sortlist list</span>`,
                                                userId: currentUser.uid,
                                                fID: val.fieldId,
                                                cId: val.cllgId,
                                                courseCode: val.courseCode,
                                                avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                            }
                                            addHistoryObjectToApplication(object,this.applicationData.id).catch((error)=>{
                                                console.error(error);
                                            })
                                            updateVal = false;
                                            // console.log(this.previousShortListedCollege[findIndex2],"findIndex2--------------if----------------",object,this.applicationData.id)
                                        }
                                    }
                                })
                                if(shortListArry.length > 0){
                                    if(updateVal == false) {
                                        let updateObj = {
                                            shortListedColleges: shortListArry,
                                            updatedAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                                        }
                                        updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,id,updateObj,res=>{
                                            this.isSpinner = false;
                                            let notificationObj = {
                                                title: `Shortlist Course`,
                                                message: `Your application ${this.applicationData.applicationNumber}'s shortlist course list has been updated`,
                                                type: 'applicationDetailPending',
                                                userId: this.applicationData.selectedStudent.id,
                                                applicationId: this.applicationData.id,
                                                currentUser:firebase.auth().currentUser.uid
                                            }
                                            this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                                            }).catch((error)=>{
                                                console.error(error);
                                            })
                                            this.$root.$emit('showToastMessage','ShortList update Successfully','success')
                                            // this.$toast({
                                            // component: ToastificationContent,
                                            // position: 'top-right',
                                            // props: {
                                            //     title: 'ShortList update Successfully',
                                            //     icon: 'CoffeeIcon',
                                            //     variant: 'success',                               
                                            // },
                                            // })
                                            // this.$router.push({name:'studentapplicationMgmt'}) 
                                        })
                                    }
                                }
                                else{
                                    this.isSpinner = false;
                                    this.errorShortList = 'please select some from Suggested Courses';
                                }
                            })
                            .catch(()=>{
                                let updateVal = true;
                                let currentUtcString = new Date().toISOString();
                                this.suggestedColleges.forEach(val=>{ //Suggested Course
                                    if(val.isSelect == true){
                                        //   console.log(val,"JYOTI YADAV")
                                        this.allSuggestedCollege.forEach(elem=>{
                                            if(elem.courseUniqueId == val.courseUniqueId)
                                            {
                                                let findIndex = this.allShortlistedCollege.findIndex(dataArry=>{ //Shorlist Course
                                                    return elem.courseUniqueId == dataArry.courseUniqueId
                                                })
                                                if(findIndex > -1){
                                                    elem.status = this.allShortlistedCollege[findIndex].shortlist_status;
                                                    elem.statusText =  this.allShortlistedCollege[findIndex].statusText;
                                                    elem.statusVariant = this.allShortlistedCollege[findIndex].statusVariant;                                                              
                                                }else{
                                                    elem.status = 'pending';
                                                    elem.statusVariant = 'primary';
                                                    elem.statusText = 'Pending';
                                                }
                                                let index = this.previousShortListedCollege.findIndex((element)=>{
                                                    return (element.courseUniqueId == val.courseUniqueId)
                                                })
                                                if(index == -1){
                                                    elem.sortListDate = new Date(currentUtcString);
                                                } else {
                                                    elem.sortListDate = this.previousShortListedCollege[index].sortListDate !== '-' ? new Date(this.previousShortListedCollege[index].sortListDate) : '';
                                                }
                                                if(elem.sortListDate == 'Invalid Date') {
                                                    elem.sortListDate = new Date(currentUtcString);
                                                }

                                                let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                                        return (element.courseUniqueId == val.courseUniqueId) && elem.courseUniqueId == element.courseUniqueId
                                                    })
                                                if(findIndex2 == -1){
                                                    let object = {
                                                        createdAt: new Date(currentUtcString),
                                                        message: `<span><b>${currentUserObject.fullName}</b> has added this to sortlist list</span>`,
                                                        userId: currentUser.uid,
                                                        fID: elem.fieldId,
                                                        cId: elem.id,
                                                        courseCode: elem.courseCode,
                                                        avatar: currentUserObject.avatar ? currentUserObject.avatar : '',

                                                    }
                                                    // console.log("findIndex2--------------if---------",object,this.applicationData.id)
                                                    addHistoryObjectToApplication(object,this.applicationData.id).catch((error)=>{
                                                        console.error(error);
                                                    })
                                                    updateVal = false;
                                                }
                                                shortListArry.push(elem)  
                                            }
                                        })
                                    } else {
                                        let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                            return (element.courseUniqueId == val.courseUniqueId)
                                        })
                                        if(findIndex2 !== -1){
                                            let object = {
                                                createdAt: new Date(currentUtcString),
                                                message: `<span><b>${currentUserObject.fullName}</b> has removed this from sortlist list</span>`,
                                                userId: currentUser.uid,
                                                fID: val.fieldId,
                                                cId: val.cllgId,
                                                courseCode: val.courseCode,
                                                avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                            }
                                            addHistoryObjectToApplication(object,this.applicationData.id).catch((error)=>{
                                                console.error(error);
                                            })
                                            updateVal = false;
                                            // console.log(this.previousShortListedCollege[findIndex2],"findIndex2--------------if----------------",object,this.applicationData.id)
                                        }
                                    }
                                })
                                if(shortListArry.length > 0){
                                    if(updateVal == false) {
                                        let updateObj = {
                                            shortListedColleges: shortListArry,
                                            updatedAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                                        }
                                        updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,id,updateObj,res=>{
                                            this.isSpinner = false;
                                            let notificationObj = {
                                                title: `Shortlist Course`,
                                                message: `Your application ${this.applicationData.applicationNumber}'s shortlist course list has been updated`,
                                                type: 'applicationDetailPending',
                                                userId: this.applicationData.selectedStudent.id,
                                                applicationId: this.applicationData.id,
                                                currentUser:firebase.auth().currentUser.uid
                                            }
                                            this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                                            }).catch((error)=>{
                                                console.error(error);
                                            })
                                            this.$root.$emit('showToastMessage','ShortList update Successfully','success')
                                            // this.$toast({
                                            // component: ToastificationContent,
                                            // position: 'top-right',
                                            // props: {
                                            //     title: 'ShortList update Successfully',
                                            //     icon: 'CoffeeIcon',
                                            //     variant: 'success',                               
                                            // },
                                            // })
                                            // this.$router.push({name:'studentapplicationMgmt'}) 
                                        })
                                    }
                                }
                                else{
                                    this.isSpinner = false;
                                    this.errorShortList = 'please select some from Suggested Courses';
                                }
                            })
                      }  
                    }else{
                        this.isSpinner = false;
                        this.errorShortList = 'please select some from Suggested Courses';
                    }
            }else{
            this.isSpinner = false;
            }
            // this.isSpinner = false;
        },
        
        init(){
            getQuery.getDataWithSpecificWhereCondition(dbCollections.COLLEGES,'isDeleted','==',false,res=>{
                this.allColleges = res
            })
        },
        getFieldAndCourse(data,appData,cb){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.FIELDANDCOURSE,data.fieldName.id,uniRes=>{
                    // obj.universityName = uniRes.universityName
                    if(Object.keys(uniRes).length > 0){
                    if(uniRes.isDeleted == false){
                        if(uniRes.courses.length > 0){
                            let searchName = uniRes.courses.findIndex(values=>{
                                return values.id == appData.courseCode
                            })
                            if(searchName > -1){
                                let datas = uniRes.courses[searchName];
                                datas.fieldName = uniRes.fieldName 
                                cb(datas)
                            }else{
                                cb(false)
                            }
                        }else{
                        cb(false)
                        }
                    }else{
                        cb(false)
                    }
                    }else{
                        cb(false)
                    }
            })
        },  
        getCllgAndUniData(data,cb){
            let self = this;
            let obj = {}
            // console.log(data,"appData")
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.COLLEGES,data.id,res=>{
                obj.cllgName = res.collegeName;
                if(res.courses.length > 0){
                    let searchInd = res.courses.findIndex(courseEle=>{
                        return courseEle.fieldName.id == data.fieldId && courseEle.courseName.id == data.courseCode
                    })
                    if(searchInd > -1){
                        obj.courseFinalData = res.courses[searchInd];
                    }else{
                        obj.courseFinalData = null
                    }    
                }
                getQuery.getDataFromRootCollectionWithDocID(dbCollections.UNIVERSITIES,res.universityId,uniRes=>{
                    obj.universityName = uniRes.universityName
                    obj.uniId = res.universityId
                    if(res.courses !== undefined && res.courses.length > 0){
                    //     //HERE WHEN COLLEGE CONTAIN COURSES
                        obj.courseAndField = [];
                        // console.log(res.courses,"res.courses")
                        let fileArry = res.courses
                        let count = 0;
                        const uploadFileFunction = (row) => {
                        if(count >= fileArry.length){  
                            cb(obj)
                            return;                 
                        }
                            self.getFieldAndCourse(row,data,(saveRes)=>{
                                let courseData = {
                                    courseName :'',
                                    courseId :'',
                                    fieldName :''
                                }
                                if(saveRes !== false){
                                    courseData = {
                                        courseName :saveRes.name,
                                        courseId :saveRes.id,
                                        fieldName :saveRes.fieldName
                                    }
                                    obj.courseAndField = courseData;
                                    // cb(obj)
                                }
                                if(count <= Number(fileArry.length - 1)){
                                count += 1;
                                uploadFileFunction(fileArry[count]);
                                }else{
                                    cb(obj)
                                }
                            });              
                        }
                        uploadFileFunction(fileArry[count]);
                     }else{
                        cb(obj)
                     }    
                })
            })
        },
        getData(){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.APPLICATIONS,this.$route.params.id,res=>{
                if(res){
                    this.$root.$emit('startSpinner');
                    this.isLoading = true;
                    this.applicationData = [];
                    this.applicationData = res;
                    this.applicationDataInitial = res;
                    this.applicationData.applicationClose = res.applicationClose||false;

                    this.allShortlistedCollege = []
                    this.allSuggestedCollege = []
                    this.allSuggestedCollege = res.selectedCollege

                    this.collegeOptions = [];
                    this.applicationData.dispIntake = this.applicationData.intake.toString();
                    if(this.applicationData.selectedCollege.length > 0){
                        this.suggestedColleges = [];
                        this.tempSuggestColleg = [];
                        this.selectedCollegeId = [];
                        this.shortListedCollege = [];
                        let examsName = []
                        let intakeArry = []

                        let fileArry = this.applicationData.selectedCollege; //SPECIFIC APP. SUGGESTEDLIST  

                        let count = 0;
                        const uploadFileFunction = (element) => {
                            if(count >= this.applicationData.selectedCollege.length){ 
                                const tmpData = [...this.suggestedColleges];
                                let newArray = [];
                                let uniqueObject = {};
                                for (let i in tmpData) {
                                    let filedKey = tmpData[i]['no'];
                                    uniqueObject[filedKey] = tmpData[i];
                                }
                                for (let i in uniqueObject) {
                                    newArray.push(uniqueObject[i]);
                                }
                                this.suggestedColleges = [...newArray];          
                                // this.suggestedColleges.sort((a, b) => {
                                //     return this.sortListOrder.indexOf(b.clgStatus) - this.sortListOrder.indexOf(a.clgStatus);
                                // });                 
                                this.isLoading = false;
                                this.$root.$emit('stopSpinner');
                                this.suggestedColleges.sort((a,b)=>{
                                    return b.isSelect - a.isSelect
                                });
                                this.previousShortListedCollege = [...this.allShortlistedCollege];
                                return                  
                            }else{
                                try {
                                        this.selectedCollegeId.push(element.id)
                                        if(element.dispIntakeDeadline.length > 0){
                                            element.dispIntakeDeadline.forEach(deacline=>{
                                                deacline.DispDate = new Date(deacline.date)
                                                deacline.DispDate = moment(deacline.DispDate).format('DD MMM, YYYY'); 
                                            })
                                        }
                                        if(element.dispIntakeDeadline.length > 0){
                                            element.dispIntakeDeadline.forEach(deadElement => {
                                                deadElement.disDate = moment(new Date(deadElement.date)).format('DD MMM, YYYY') 
                                            });                               
                                        }
                                        let suggestedObj = {
                                            'no':Number(count + 1),
                                            'isSelect':false,
                                            'cllgName':element.college_name,
                                            'uniName':element.university_name,
                                            'fieldName':element.dispFieldName,
                                            'courceName':element.dispCourseName,
                                            'fieldId':element.fieldId,
                                            'courseCode':element.courseCode,
                                            'modeofApp':element.dispmodeOfApplication,
                                            'intake':element.dispIntake,
                                            'applicationDeadLine':element.dispIntakeDeadline,
                                            'examsScores': element.examsScore,
                                            'cllgId': element.id,
                                            'clgStatus':'',
    
                                            'applicationFee': element?.applicationFee?.length ? element.applicationFee : '--',
                                            'tutionFee': element?.tutionFee?.length ? element.tutionFee :'--',
    
    
                                            'courseName':element.dispCourseName,
                                            'modeOfApplication':element.dispmodeOfApplication,
                                            'examsAndScore':element.examsScore,
                                            'id':element.id,
                                            'uniId':element.universityId,
                                            'courseUniqueId': element.courseUniqueId,
                                            'germanLanguage': element.germanLanguage || '',
                                            'remark': element.remark || ''
                                        }
                                        // field filter array
                                        if(this.fieldOptions.length > 0){
                                            let fieldInd = this.fieldOptions.findIndex(valls=>{
                                                // return valls == element.dispFieldName;
                                                return valls == element.dispFieldName;
                                            })
                                            if(fieldInd <= -1){
                                                // this.fieldOptions.push(elem)
                                                 this.fieldOptions.push(element.dispFieldName)
                                            }
    
                                        }else{
                                        this.fieldOptions.push(element.dispFieldName)
                                        }
                                        //COLLEGE OPTION ARRAY
                                        if(this.collegeOptions.length <= 0){
                                         this.collegeOptions.push(element.college_name)
                                        }else{
                                            let cllgInd = this.collegeOptions.findIndex(valls=>{                                          
                                                return valls == element.college_name;
                                            })
                                            if(cllgInd <= -1){
                                                 this.collegeOptions.push(element.college_name)
                                            }
                                        }
    
                                        // course filter array
                                        if(this.courseOptions.length > 0){
                                            let fieldInd = this.courseOptions.findIndex(valls=>{
                                                return valls == element.dispCourseName;
                                                // return valls == element.dispCourseName;
                                            })
                                            if(fieldInd <= -1){
                                                this.courseOptions.push(element.dispCourseName)
                                                // this.courseOptions.push(element.dispCourseName)
                                            }
    
                                        }else{
                                        this.courseOptions.push(element.dispCourseName)
                                        // this.courseOptions.push(element.dispCourseName)
                                        }
                                        //UNIVERSITY FILTER
                                        if(this.universityOptions.length <= 0){
                                            this.universityOptions.push({'label':element.university_name,'value':element.universityId})
                                        }else{
                                             let uniInd = this.universityOptions.findIndex(valls=>{                                          
                                                return valls.value == element.universityId;
                                            })
                                            if(uniInd <= -1){
                                                this.universityOptions.push({'label':element.university_name,'value':element.universityId})
                                            }
                                        }
                                        // intake filter
                                        if(element.dispIntake.length > 0){
                                            element.dispIntake.forEach(ele=>{
                                                if(intakeArry.length > 0){
                                                    let findInd = intakeArry.findIndex(exm=>{
                                                        return exm == ele
                                                    })
                                                    if(findInd <= -1){
                                                    intakeArry.push(ele)
                                                    }
                                                }else{
                                                intakeArry.push(ele)
                                                }
                                            })
                                            this.intakeOptions = intakeArry;
                                        }
                                        // EXAMS FILTER
                                        if(element.examsScore.length > 0){
                                            element.examsScore.forEach(ele=>{
                                                if(examsName.length > 0){
                                                    let findInd = examsName.findIndex(exm=>{
                                                        return exm == ele.title
                                                    })
                                                    if(findInd <= -1){
                                                    examsName.push(ele.title)
                                                    }
                                                }else{
                                                examsName.push(ele.title)
                                                }
                                            })
                                            this.examOptions = examsName;
                                        }                            
                                        this.tempSuggestColleg = this.suggestedColleges;
    
                                        if(element.dispIntakeDeadline.length > 0){
                                            element.dispIntakeDeadline.forEach(deadlineDate=>{
                                                deadlineDate.DispDate = moment(new Date(deadlineDate.date)).format('DD MMM') 
                                            })
                                            suggestedObj.applicationDeadline = element.dispIntakeDeadline;
                                        }                          
    
                                        if(this.applicationData.shortListedColleges !== undefined && this.applicationData.shortListedColleges.length > 0){
                                            this.applicationData.shortListedColleges.forEach((values,index) => { 
                                                if(values.courseUniqueId == this.applicationData.selectedCollege[count].courseUniqueId){                                      
                                                    if(values.dispIntakeDeadline.length > 0){
                                                            values.dispIntakeDeadline.forEach(deacline=>{
                                                                deacline.DispDate = new Date(deacline.date)
                                                                deacline.DispDate = moment(deacline.DispDate).format('DD MMM, YYYY'); 
                                                            })
                                                    }
                                                    suggestedObj.isSelect = true;
                                                    let statusVariant  = values.statusVariant ? values.statusVariant : '';
                                                    let statusText = values.statusText ? values.statusText : '';                              
                                                    if(statusVariant == '' || statusText == ''){
                                                        if(values.status == 'pending'){
                                                            statusVariant = 'primary';
                                                            statusText = 'Pending';
                                                        }
                                                        if(values.status == 'inProgress'){
                                                            statusVariant = 'warning';
                                                            statusText = 'In Progress';
                                                        }
                                                        if(values.status == 'paymentPending'){
                                                            statusVariant = 'secondary'
                                                            statusText = 'Payment Pending';
                                                        }
                                                        if(values.status == 'paymentSuccessful'){
                                                            statusVariant = 'info'
                                                            statusText = 'Payment Successful';
                                                        }
                                                        if(values.status == 'applicationSubmitted'){
                                                            statusVariant = 'dark'
                                                            statusText = 'Application Submitted';
                                                        }
                                                        if(values.status == 'admissionSuccessful'){
                                                            statusVariant = 'success'
                                                            statusText = 'Admission Offered';
                                                        }
                                                        if(values.status == 'admissionRejected'){
                                                            statusVariant = 'danger'
                                                            statusText = 'Rejection';
                                                        }   
                                                    }
    
                                                    let disStatus = values.status ? values.status : 'pending' ;
                                                    let statusArray =  [
                                                        {text:'Pending',value:'pending'},
                                                        {text:'In Progress',value:'inProgress'},
                                                        {text:'Admission Offered',value:'admissionSuccessful'},
                                                        {text:'Rejection',value:'admissionRejected'},
                                                        {text:'Application Submitted',value:'applicationSubmitted'},
                                                        {text:'Payment Pending',value:'paymentPending'},
                                                        {text:'Payment Successful',value:'paymentSuccessful'}
                                                    ];
                                                    let statusInd = statusArray.findIndex(statusEle=>{
                                                    return values.status == statusEle.value 
                                                    })
                                                    if(statusInd > -1){
                                                        disStatus = statusArray[statusInd].text;
                                                    }
    
                                                    let shortFinalObj = {
                                                        'no':Number(this.allShortlistedCollege.length),
                                                        'cllgName':values.college_name,
                                                        'courseCode':values.courseCode,
                                                        'fieldId':values.fieldId,
                                                        'uniName':values.university_name,                                
                                                        'fieldName':values.dispFieldName,
                                                        'courseName':values.dispCourseName,
                                                        'modeOfApplication':values.dispmodeOfApplication,
                                                        'sortListDate': values.sortListDate ? moment(values.sortListDate.seconds * 1000).format('DD MMM YYYY hh:mm A') : '-',
                                                        'intake':values.dispIntake,
                                                        'applicationDeadline':values.dispIntakeDeadline,
                                                        'examsAndScore':values.examsScore,
                                                        'id':values.id,
                                                        'applicationId':res.id,
                                                        'statusVariant':statusVariant,
                                                        'statusText':statusText,
                                                        'shortlistStatus': values.status,
                                                        'pendingPaymentFile': values.uploadPayFile ? values.uploadPayFile : '',
                                                        
                                                        'shortlist_status': values.status,
                                                        'cllgId': fileArry[count].id,
    
                                                        'applicationFee': values?.applicationFee?.length ? values.applicationFee : '--',
                                                        'tutionFee': values?.tutionFee?.length ? values.tutionFee : '--',
    
    
    
                                                        'isSelect':true,                                                
    
                                                        
                                                        'application_Id':res.id, //spell
                                                        'courceName':element.dispCourseName, //spell 
                                                        
                                                        'modeofApp':fileArry[count].dispmodeOfApplication,
                                                        
                                                        'applicationDeadLine':fileArry[count].dispIntakeDeadline,
                                                        'examsScores': fileArry[count].examsScore,
                                                        
                                                        'applicationStatus':disStatus,
                                                        'admissionStatus':'',
                                                        'application_Status': values.status,
    
                                                        'paymentPendingText':values.paymentPendingText ? values.paymentPendingText : '',
                                                        'courseUniqueId':values.courseUniqueId,
                                                        'germanLanguage': values.germanLanguage || '',
                                                        'remark': values.remark || ''
                                                    } 

                                                        if(values.dispIntakeDeadline.length > 0){
                                                            values.dispIntakeDeadline.forEach(deadlineDate=>{
                                                                // deadlineDate.DispDate = moment(new Date(deadlineDate.date)).format('DD MMM, YYYY')
                                                                deadlineDate.DispDate = moment(new Date(deadlineDate.date)).format('DD MMM')
                                                            })
                                                            shortFinalObj.applicationDeadline = values.dispIntakeDeadline;
                                                        }
    
                                                    this.shortListedCollege.push(shortFinalObj)
    
                                                    suggestedObj.clgStatus = values.status
                                                    // console.log(shortListObj,"shortListObj")
                                                    this.allShortlistedCollege.push(shortFinalObj)
                                            }  
                                            })
                                        }
                                        this.suggestedColleges.push(suggestedObj)
                                        //NEXT OBJ
                                        count += 1;
                                        uploadFileFunction(this.applicationData.selectedCollege[count]);                         
                                } catch (error) {
                                    console.error("ERROR: ", error);
                                    count += 1;
                                    uploadFileFunction(this.applicationData.selectedCollege[count]);                        
                                }
                            }         
                        }
                        this.suggestedColleges = [];
                        this.collegeOptions = [];
                        this.universityOptions = [];
                        uploadFileFunction(this.applicationData.selectedCollege[count]);      
                        if(this.selectedCollegeId.length > 0){
                            let count = 0;
                            // this.collegeOptions = [];
                            // this.universityOptions = [];
                            const uploadFileFunction = (row) => {
                            if(count >= this.selectedCollegeId.length){                             
                                return;                   
                            }else{
                                this.getCollegeFilterOption(row,(saveRes)=>{
                                    if(saveRes !== false){
                                        if(this.collegeOptions.length > 0){
                                            let findVal = this.collegeOptions.findIndex(elem=>{
                                               return elem == saveRes.cllgLabel; 
                                            })
                                            if(findVal > -1){
                                            }else{
                                                this.collegeOptions.push(saveRes.cllgLabel)
                                                if(this.universityOptions.length > 0){
                                                        let findUniVal = this.universityOptions.findIndex(uniElem=>{
                                                        return uniElem.value == saveRes.uniValue; 
                                                        }) 
                                                        if(findUniVal > -1){
                                                        }else{
                                                            this.universityOptions.push({
                                                                'label':saveRes.uniLabel,
                                                                'value':saveRes.uniValue
                                                            })
                                                        }
                                                }                                            
                                            }
                                        }else{
                                            this.collegeOptions.push(saveRes.cllgLabel)
                                            this.universityOptions.push({
                                                'label':saveRes.uniLabel,
                                                'value':saveRes.uniValue
                                            })
                                        }
                                    }
                                    count += 1;
                                    uploadFileFunction(this.selectedCollegeId[count]);
                                });   
                           }           
                            }
                            uploadFileFunction(this.selectedCollegeId[count]);
                        }
                   }
                    if(this.statusArry.length > 0){
                        let findInd = this.statusArry.findIndex(ret=>{
                            return ret.value.toLowerCase() == this.applicationData.application_Status.toLowerCase();
                        })
                        if(findInd > -1){
                            this.selectedStatus = this.statusArry[findInd].text;
                            if(this.statusArry[findInd].value == 'pending'){
                                this.applicationStatusVariant = 'primary';
                            }
                            if(this.statusArry[findInd].value == 'inProgress'){
                                this.applicationStatusVariant = 'warning';
                            }
                            if(this.statusArry[findInd].value == 'paymentPending'){
                                this.applicationStatusVariant = 'secondary';
                            }
                            if(this.statusArry[findInd].value == 'paymentSuccessful'){
                                this.applicationStatusVariant = 'info';
                            }
                            if(this.statusArry[findInd].value == 'applicationSubmitted'){
                                this.applicationStatusVariant = 'Dark';
                            }
                            if(this.statusArry[findInd].value == 'admissionSuccessful'){
                                this.applicationStatusVariant = 'success';
                            }
                            if(this.statusArry[findInd].value == 'admissionRejected'){
                                this.applicationStatusVariant = 'danger';
                            }
                        }
                    }
                    
                    getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,this.applicationData.selectedStudent.id,userRes=>{
                        this.studentData = userRes
                    })
                } else {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.$root.$emit('stopSpinner');
                    });
                }
                // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> H3');
            })
        },
        getCollegeFilterOption(data,cb){
            if(data !== undefined){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.COLLEGES,data,res=>{
                if(res.isDeleted == false){
                getQuery.getDataFromRootCollectionWithDocID(dbCollections.UNIVERSITIES,res.universityId,uniRes=>{            
                    cb({'cllgLabel':res.collegeName,'cllgValue':res.id,'uniLabel':uniRes.universityName,'uniValue':res.universityId})
                })
                }else{
                    cb(false)
                }
            })
            }
        },
        updateSuggestStatus(statusData){
            this.isSppiner = true
            if(this.selectedStatus !== null){
                let  id = this.$route.params.id;
                let obj = {
                    'application_Status': statusData.value,
                    'updatedAt':firebase.firestore.FieldValue.serverTimestamp()
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,id,obj,res=>{
                    let notificationObj = {
                        title: `Application Status`,
                        message: `Your application ${this.applicationData.applicationNumber} is updated to "${statusData.text}"`,
                        type: statusData.type,
                        userId: this.applicationData.selectedStudent.id,
                        applicationId: this.applicationData.id,
                        currentUser:firebase.auth().currentUser.uid
                    }
                    this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                    }).catch((error)=>{
                        console.error(error);
                    })
                    this.isSppiner = false
                })
            }
        },
        updateShortListStatus(item,statusData){
            if(item.application_Status == statusData.value) {
                return;
            }
            this.isSppiner = true;
            let currentUser = firebase.auth().currentUser;
            let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
            if(statusData.value !== 'paymentPending'){ 
                let  applicationId = this.$route.params.id;
                let shortCollegeListId = item.id;
                if(this.applicationData.shortListedColleges !== undefined && this.applicationData.shortListedColleges.length > 0){
                    let searchIndex = this.applicationData.shortListedColleges.findIndex(val=>{
                        return item.courseUniqueId == val.courseUniqueId
                    })
                    if(searchIndex > -1){
                        this.applicationData.shortListedColleges[searchIndex].status = statusData.value
                        this.applicationData.shortListedColleges[searchIndex].statusVariant = statusData.variant
                        this.applicationData.shortListedColleges[searchIndex].statusText = statusData.text
                    }
                }
                let obj = {
                    'shortListedColleges': this.applicationData.shortListedColleges,
                    updatedAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                }
                if(item.application_Status == 'admissionSuccessful' && (this.applicationData.finalSelected && item.courseUniqueId == this.applicationData.finalSelected.courseUniqueId)) {
                    obj.finalSelected = {}
                    obj.applicationClose = false
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,applicationId,obj,res=>{
                    let searchIndex = this.applicationData.shortListedColleges.findIndex(val=>{
                        return item.courseUniqueId == val.courseUniqueId
                    })
                    if(searchIndex > -1){
                        let notificationObj = {
                            title: `Shortlist Course Status`,
                            message: `Your application ${this.applicationData.applicationNumber}'s course ${this.applicationData.shortListedColleges[searchIndex].dispCourseName} in ${this.applicationData.shortListedColleges[searchIndex].college_name}'s status changed to "${this.applicationData.shortListedColleges[searchIndex].statusText}"`,
                            type: statusData.type,
                            userId: this.applicationData.selectedStudent.id,
                            applicationId: this.applicationData.id,
                            currentUser:firebase.auth().currentUser.uid
                        }
                        let object = {
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                            // message: `${currentUserObject.fullName} changed application ${this.applicationData.applicationNumber}'s status to "${this.applicationData.shortListedColleges[searchIndex].statusText}" for course ${this.applicationData.shortListedColleges[searchIndex].dispCourseName} in ${this.applicationData.shortListedColleges[searchIndex].college_name}.`,
                            message: `<span><b>${currentUserObject.fullName}</b> has changed status to <b>${this.applicationData.shortListedColleges[searchIndex].statusText}</b></span>`,
                            userId: currentUser.uid,
                            fID: this.applicationData.shortListedColleges[searchIndex].fieldId,
                            cId: this.applicationData.shortListedColleges[searchIndex].id,
                            courseCode: this.applicationData.shortListedColleges[searchIndex].courseCode,
                            avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                        }
                        addHistoryObjectToApplication(object,this.applicationData.id).catch((error)=>{
                            console.error(error);
                        })
                        this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                        }).catch((error)=>{
                            console.error(error);
                        })
                        this.isSppiner = false
                    } else {
                        this.isSppiner = false
                    }
                })
            }else{
                this.payPenddingObject = item; 
                this.paymentPendingStatus = statusData;   
                this.paymentPendingConfirm = true;
                this.isSppiner = false
            } 
        },
        filterByCollege(data){
            return data.filter(clg => !clg.cllgName.indexOf(this.collegeFilter))
        },
        filterByUniversity(data){
            return data.filter(clg => !clg.uniId.indexOf(this.universityFilter))
        },
        filterByField(data){
            return data.filter(clg => !clg.fieldName.indexOf(this.fieldFilter))
        },
        filterByCourse(data){
            return data.filter(clg => clg.courseName==this.courseFilter)
        },
        filterByIntake(data){
            return data.filter((clg) => {
                var found = false
                clg.intake.forEach(val=>{
                    if(!val.indexOf(this.intakeFilter))
                    {
                        found = !val.indexOf(this.intakeFilter)
                    }
                })
                return found;
            })
        },
        filterByMOA(data){
            return data.filter(clg => clg.modeOfApplication.toLowerCase()==this.modeOfApp.value.toLowerCase())
        },
        filterByExam(data){
            return data.filter((clg) => {
                var found = false
                clg.examsAndScore.forEach(val=>{
                    if(!val.title.indexOf(this.examFilter))
                    {
                        found = !val.title.indexOf(this.examFilter)
                    }
                })
                return found;
            })
        },
        filterByScore(data){
            return data.filter((clg) => {
                var found = false
                clg.examsAndScore.forEach(val=>{
                    if(val.score<=this.bandScore.value)
                    {
                        found = val.score<=this.bandScore.value
                    }
                })
                return found;
            })
        },

        NotesCollegePaymentPending(){
            var self = this;
            self.$refs.paymentPendingNotes.validate().then(success => {
                if (success) {
                    let currentUser = firebase.auth().currentUser;
                    let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
                    if(self.payPenddingObject !== null){  
                        let obj = {};
                        let searchIndex = this.applicationData.shortListedColleges.findIndex(values=>{
                            return values.courseUniqueId == self.payPenddingObject.courseUniqueId; 
                        }) 
                        if(this.applicationData.finalSelected && Object.keys(this.applicationData.finalSelected).length > 0 && self.payPenddingObject.shortlist_status == 'admissionSuccessful' && this.applicationData.shortListedColleges[searchIndex].id == self.payPenddingObject.id && this.applicationData.finalSelected.fieldId == self.payPenddingObject.fieldId && this.applicationData.finalSelected.courseCode == self.payPenddingObject.courseCode) {
                            obj.finalSelected = {}
                            obj.applicationClose = false
                        }
                        if(searchIndex > -1){
                            if(Object.keys(this.applicationData.shortListedColleges[searchIndex]).length > 0){  //object os shortlist in which we add text of paymentPending
                                this.applicationData.shortListedColleges[searchIndex].paymentPendingText = self.pendingNotes;      
                                this.applicationData.shortListedColleges[searchIndex].status = 'paymentPending';                                 
                                this.applicationData.shortListedColleges[searchIndex].statusVariant = 'secondary';                                 
                                this.applicationData.shortListedColleges[searchIndex].statusText = 'Payment Pending';                                 
                            }
                            let updateObject = {
                                'shortListedColleges': this.applicationData.shortListedColleges,
                                'updatedAt': firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                            }
                            if(obj && Object.keys(obj).length > 0){
                                updateObject.finalSelected =  obj.finalSelected;
                                updateObject.applicationClose = false
                                obj = {}
                            }
                            updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,self.payPenddingObject.applicationId,updateObject,res=>{
                                self.paymentPendingNotes = false;
                                self.pendingNotes = "";
                                self.$bvModal.hide('modal-Payment-Pending-Notes')
                                let notificationObj = {
                                    title: `Shortlist Course Status`,
                                    message: `Your application ${this.applicationData.applicationNumber}'s course ${this.applicationData.shortListedColleges[searchIndex].dispCourseName} in ${this.applicationData.shortListedColleges[searchIndex].college_name}'s status changed to "${this.applicationData.shortListedColleges[searchIndex].statusText}"`,
                                    type: 'applicationDetailPaymentPending',
                                    userId: this.applicationData.selectedStudent.id,
                                    applicationId: this.applicationData.id,
                                    currentUser:firebase.auth().currentUser.uid
                                }
                                let object = {
                                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                                    // message: `${currentUserObject.fullName} changed application ${this.applicationData.applicationNumber}'s status to "${this.applicationData.shortListedColleges[searchIndex].statusText}" for course ${this.applicationData.shortListedColleges[searchIndex].dispCourseName} in ${this.applicationData.shortListedColleges[searchIndex].college_name}.`,
                                    message: `<span><b>${currentUserObject.fullName}</b> has changed status to <b>${this.applicationData.shortListedColleges[searchIndex].statusText}</b></span>`,
                                    userId: currentUser.uid,
                                    fID: this.applicationData.shortListedColleges[searchIndex].fieldId,
                                    cId: this.applicationData.shortListedColleges[searchIndex].id,
                                    courseCode: this.applicationData.shortListedColleges[searchIndex].courseCode,
                                    avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                }
                                addHistoryObjectToApplication(object,this.applicationData.id).catch((error)=>{
                                    console.error(error);
                                })
                                this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                                }).catch((error)=>{
                                    console.error(error);
                                })
                                self.$root.$emit('showToastMessage','Status Updated Successfully.','success');
                            })
                        }else{
                        self.paymentPendingNotes = false;
                        self.pendingNotes = "";
                        self.$bvModal.hide('modal-Payment-Pending-Notes')
                        self.$root.$emit('showToastMessage','Something went wrong please try again.','danger')
                        }
                    }else{
                        self.paymentPendingNotes = false;
                        self.pendingNotes = "";
                        self.$bvModal.hide('modal-Payment-Pending-Notes')
                        self.$root.$emit('showToastMessage','Something went wrong please try again.','danger')
                    }  
                }
            })  
        },
        downloadFile(item) {
        // var URL = url.split('?alt')
        var self = this

        item.forEach(data=>{
            self.$axios({
                url: data.imageUrl,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
    
                fileLink.href = fileURL;
                fileLink.setAttribute('download', data.imageName);
                document.body.appendChild(fileLink);
    
                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
            })
        })

        },
    },  
    computed: {
        filterList()
        {
            var listArr = this.suggestedColleges
            var tmp = listArr
            if(this.collegeFilter!= null)
            {
                tmp = this.filterByCollege(tmp)
            }
            if(this.universityFilter!= null)
            {
                tmp = this.filterByUniversity(tmp)
            }
            if(this.fieldFilter!= null)
            {
                tmp = this.filterByField(tmp)
            }
            if(this.courseFilter!= null)
            {
                tmp = this.filterByCourse(tmp)
            }
            if(this.intakeFilter!= null)
            {
                tmp = this.filterByIntake(tmp)
            }
            if(this.examFilter!= null)
            {
                tmp = this.filterByExam(tmp)
            }
            if(this.bandScore!= null)
            {
                tmp = this.filterByScore(tmp)
            }
            if(this.modeOfApp!= null)
            {
                tmp = this.filterByMOA(tmp)
            }

            // return listArr;
            if(this.collegeFilter==null && this.universityFilter==null && this.fieldFilter==null && this.courseFilter==null && this.intakeFilter==null && this.examFilter==null && this.bandScore==null && this.modeOfApp==null)
            {
                return this.suggestedColleges
            }
            else
            {
                return tmp;
            }
        },
        suggestedCollegesList(){
            var tempArry = this.suggestedColleges;
            var filteredArry = [];
            if(this.collegeFilter != null){
                if(filteredArry.length <= 0){
                    this.tempSuggestColleg.forEach((ele,index)=>{
                        if(ele.id == this.collegeFilter){             
                            if(index <=  Number(this.tempSuggestColleg.length - 1)){                                
                                filteredArry.push(ele)
                            }else{
                                filteredArry = [];
                                return;
                            }
                        }
                    })
                }else{
                    filteredArry.forEach((ele,index)=>{
                        if(ele.id == this.collegeFilter){             
                            if(index <=  Number(filteredArry.length - 1)){
                                let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                                })
                                if(fielIndex <= -1){
                                filteredArry.push(ele)
                                }
                            }else{
                                filteredArry = [];
                                return;
                            }
                        }
                    }) 
                }
                tempArry = filteredArry;
            }
            if(this.universityFilter != null){
                if(filteredArry.length <= 0){
                this.tempSuggestColleg.forEach((ele,index)=>{
                    if(ele.uniId == this.universityFilter){           
                        if(index <=  Number(this.tempSuggestColleg.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.uniId == this.universityFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            }
            if(this.fieldFilter != null){
                if(filteredArry.length <= 0){
                this.tempSuggestColleg.forEach((ele,index)=>{
                    if(ele.fieldName == this.fieldFilter){           
                        if(index <=  Number(this.tempSuggestColleg.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.fieldName == this.fieldFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            }
            if(this.courseFilter != null){
                if(filteredArry.length <= 0){
                this.tempSuggestColleg.forEach((ele,index)=>{
                    if(ele.courseName == this.courseFilter){           
                        if(index <=  Number(this.tempSuggestColleg.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.courseName == this.courseFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            }
            if(this.intakeFilter != null){
                if(filteredArry.length <= 0){
                this.tempSuggestColleg.forEach((ele,index)=>{
                    let indexIntake = ele.intake.findIndex(intele=>{
                        return intele == this.intakeFilter
                    })
                    if(indexIntake > -1){                        
                        filteredArry.push(ele)                        
                    }                 
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    let indexIntake = ele.intake.findIndex(intele=>{
                        return intele == this.intakeFilter
                        })
                        if(indexIntake > -1){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }                        
                        }else{
                            filteredArry = [];
                            return;
                        }         
                        
                    })
                }
                tempArry = filteredArry;
            }
            if(this.examFilter != null){
                if(filteredArry.length <= 0){
                this.tempSuggestColleg.forEach((ele,index)=>{
                    let indexIntake = ele.examsAndScore.findIndex(intele=>{
                        return intele.title == this.examFilter
                    })
                    if(indexIntake > -1){                        
                        filteredArry.push(ele)                        
                    }                 
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    // if(ele.intake == this.examFilter){           
                        let indexIntake = ele.examsAndScore.findIndex(intele=>{
                        return intele.title == this.examFilter
                        })
                        if(indexIntake > -1){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        // }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    })
                }
                tempArry = filteredArry;
            }
            return  tempArry 
        },
        dataMeta(){
             const localItemsCount = this.suggestedCllgTable ? this.suggestedCllgTable.length : 0
                return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.filterList.length,
                }
        }
    } 
}
</script>
<style lang="scss">
.mr-10 {
    margin-right: 10px !important;
}
.sortlisttable .table td {
    white-space: nowrap;
}
.tableBody {
    max-height: 350px;
    overflow: auto;
    width: 100%;
}
div#activity-log-history___BV_modal_outer_ .modal-dialog {
    max-width: 620px !important;
}
div#activity-log-history___BV_modal_body_ {
    padding: 24px 20px 30px 20px;
}
label.s_modal_title {
    font-size: 22px;
    margin-bottom: 20px;
}
.addUniqClass {
    font-weight: 700;
    color: black;
}
.tableBody::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: #CFCFCF;
        border-radius: 6px;
    }
.tableBody::-webkit-scrollbar-thumb {
    background: #173c68;
    border-radius: 6px;
    }
.per-page-selector {
  width: 170px;
}
.propsObj{
  visibility: hidden;
}
.text-overflow {
  height: 20px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}
.btn-overflow {
  text-decoration: none;
  overflow: hidden;
}
.btn-overflowHidden{
  display: none; 
  text-decoration: none
}

.application_detail_table.table-responsive {
    padding: 0px!important;
}
//24-02-2023
.pendingCSSSClass button.btn.dropdown-toggle.btn-secondary {
    background-color: #DCDCDC !important;
    color: #82868B !important;
    border: none;
}
.pendingCSSSClass .btn-secondary:hover:not(.disabled):not(:disabled){
    box-shadow: none !important;
}
.applicationSubmitted button.btn.dropdown-toggle.btn-Dark {
    // background-color: #EEEEEE !important;
    background-color: rgba(75, 75, 75, 0.12) !important;
    color: #4b4b4b !important;
    border: none;
}
.applicationSubmitted .btn-Dark:hover:not(.disabled):not(:disabled){
    box-shadow: none !important;
}
div#activity-log-history___BV_modal_body_ {
    padding: 0px 20px 30px 20px;
}
.remarktext__elisis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 200px;
}
@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
